.component-title {
  font-size: 16px;
  font-weight: bold;
}

.component-form {
  margin-top: 20px;
  width: 85%;
  background-color: white;
  padding: 3%;
  border-radius: 5px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%),
    0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.all-width {
  width: 90%;
  margin-right: 20px;
}

.left-space {
  margin-left: 20px;
  width: 100%;
}

.updated-time-stamp {
  margin-left: 20px;
  width: 70%;
}

.inline-switch {
  display: flex;
  align-items: center;
  align-content: center;
  height: 56px;
}

.header {
  display: flex;
  justify-content: space-between;
}
