.facility-managers-container {
  margin-top: 1rem;
}
.card-container {
  border: 1px solid #bdbdbd;
  width: 75%;
  padding: 20px 15px 20px 15px;
  margin: 25px 0 25px 0;
  border-radius: 4px;
}

.manager-name {
  color: #53565a;
  font-weight: bold;
  font-size: 16px;
}

.manager-email {
  color: #7d7d7d;
  font-size: 16px;
}

.last-selected {
  color: #7d7d7d;
  font-size: 13px;
}

.enabled-circle {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 15px;
}

.no-managers {
  padding: 20px;
  border: 1px solid lightgray;
  border-radius: 4px;
  margin-top: 20px;
  background-color: #f9f9f9;
  max-width: 320px;
}
