#dropzone {
  position: relative;
  border: 1px dotted #414141;
  border-radius: 4px;
  color: #414141;
  font: bold 24px/200px arial;
  height: 130px;
  margin: 40px 160px;
  justify-content: center;
  align-items: center;
  display: flex;
}

#dropzone img {
  border-radius: 10px;
  vertical-align: middle;
  max-width: 95%;
  max-height: 95%;
}

#dropzone [type="file"] {
  cursor: pointer;
  position: absolute;
  opacity: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
