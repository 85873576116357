.forward-detail {
  display: flex;
  justify-content: space-between;
  height: 45px;
  align-items: center;
}

.forward-email {
  min-width: 230px;
}

.forward-date {
  margin-left: 15px;
}

.forward-detail-status-icon {
  margin-left: 15px;
}